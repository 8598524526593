import { BACKEND_URL } from "./Parameters";
import axios from 'axios';
import { PUBLIC_BEARER_TOKEN } from "./Parameters";

const config = {
    headers : {Authorization: `Bearer ${PUBLIC_BEARER_TOKEN}`}
}

export function getRequest(URL)
{
    // axios.get(BACKEND_URL + URL)
    return axios.get(BACKEND_URL +"/api/" + URL, config);
}

export function postRequest(URL, body)
{
    return axios.post(BACKEND_URL + "/api/" + URL, body, config);
}