import './../styles/styles.css';
import './../styles/stylesheet-responsive.css';
// import './../styles/stylesheet.css';
import logo from './../images/logo.svg';
import { getRequest } from '../Utils/Requests';
import { useEffect, useState } from 'react';
import { BACKEND_URL } from '../Utils/Parameters';
function Footer() {

    const [footerData, setFooterData] = useState({});
  useEffect(() => {
    getRequest("footer?populate=*").then(res => {
        setFooterData(res?.data?.data?.attributes);
    })
  }, []);
  
  return (
    <>
          <div className="footer withLeftTitle">
              <div className="safeArea">
                  <div className="footerCol">
                      <h3>{footerData?.LeftTitle}</h3>
                      <span dangerouslySetInnerHTML={{ __html: footerData?.LeftSideInfo}}></span>
                      </div>
                      <div className="footerCol">
                      <div className="map"><iframe src={footerData?.MapLink} width="100%" height="250" style={{border:0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></div>
                      </div>
                      <div className="footerCol">
                          <h3>{footerData?.RightSideTitle}</h3>
                          <div className="addressText">
                              {footerData?.RightSideDescription}
                          </div>
                          <ul className="smLink">
                              <li><a className="fbIcon" target="_blank" href={footerData?.FacebookLink}></a></li>
                              <li><a className="instaIcon" target="_blank" href={footerData?.InstaLink}></a></li>
                          </ul>
                      <div class="isoLogo">
                          <img src="https://res.cloudinary.com/dsw61xpzq/image/upload/v1695757050/thumbnail_iso_zee_5d1e0a5970.jpg?width=500&height=290"/>
                      </div>
                       </div>

                  </div>
              </div>
              <div className="footerCp">
                  <div className="safeArea">
                      <div className="cpHolder">
                          © copyright all rights reserved ZeeBoilers 2023
                      </div>
                      <div className="desDev">
                          Designed and developed by <a href={undefined}>Q studio</a>
                      </div>
                      <div className="clearBoth"></div>
                  </div>
              </div>
    </>
  );
}

export default Footer;
