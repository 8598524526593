import './../styles/styles.css';
import './../styles/stylesheet-responsive.css';
// import './../styles/stylesheet.css';
import logo from './../images/logo.svg';
import { getRequest } from './../Utils/Requests';
import { useEffect, useState } from 'react';
import { BACKEND_URL } from './../Utils/Parameters';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Header from './Header';
function About() {
  const [aboutData, setAboutData] = useState({});
  const [logo, setLogo] = useState({});
  const [clients, setClients] = useState([]);
  
  useEffect(() => {
    document.title="About | ZeeBoiler";
    getRequest("logo?populate=*").then(res => {
        setLogo(res.data.data.attributes);
      })

    getRequest("about?populate[0]=BannerImage&populate[1]=SecondSectionImage&populate[2]=ThirdSectionImage&populate[3]=clients.Image").then(res => {
        setAboutData(res?.data?.data?.attributes);
        setClients([...res?.data?.data?.attributes?.clients?.data]);
    document.title = "Product Details | ZeeBoiler";
    })
  }, []);

  return (
    <>
    <div className="headerBar">
              <Header/>
          </div>
          <div className="header">
              <div className="safeArea">
                  <div className="logoHolder"><Link to="/"><img src={logo?.Logo?.data?.attributes?.url} title="ZeeBoiler logo" alt="ZeeBoiler logo"/></Link></div>
                  <div className="menuHolder">
                      <ul>
                          <li>
                              <Link to="/">Home</Link>
                          </li>
                          <li>
                              <a className="selected" href={undefined}>About us</a>
                          </li>
                          <li>
                              <a href="/?scrollToProducts=1">Products</a>
                          </li>
                      </ul>
                  </div>
                  <div className="clearBoth"></div>
              </div>
          </div>
          <div className="subpageBanner" style={{backgroundImage: `url(${aboutData?.BannerImage?.data?.attributes?.formats?.large?.url})`}}>
              <div className="safeArea">
                  <div className="breadcrumbs">
                      <ul>
                          <li>
                              <Link to="/">Home</Link> .
                          </li>
                          <li>
                              About us
                          </li>
                      </ul>
                  </div>
                  <h1>{aboutData.BannerTitle}</h1>
                  <div className="bannerSummary">
                      {aboutData.BannerDescription}
                  </div>
              </div>
          </div>


          <div className="subpageBody withLeftTitle">
              <div className="spBlock">
                  <div className="safeArea">
                      <h2>{aboutData?.SecondSectionTitle}</h2>
                      <div className="blockContent">
                          <div className="leftBox">
                              {aboutData?.SecondSectionDescription}
                          </div>
                          <div className="rightBox">
                              <div className="imgHolder">
                              <img src={aboutData?.SecondSectionImage?.data?.attributes?.url}/>
                              </div>
                          </div>
                          <div className="clearBoth"></div>
                      </div>
                  </div>
              </div>
              <div className="quoteHolder">
                  <div className="safeArea">
                      <h4>Designed <br/> to never fail</h4>
                  </div>
              </div>
              <div className="spBlock">
                  <div className="safeArea">
                      <h2>{aboutData?.ThirdSectionTitle}</h2>
                      <div className="blockContent">
                          <div className="leftBox">
                              {aboutData?.ThirdSectionDescription}
                              <br/><br/>
                                  <h4>{aboutData?.ThirdSectionColOneTitleOne}</h4>
                                  {aboutData?.ThirdSectionColOneDescOne}
                                  <br/><br/>
                                      <h4>{aboutData?.ThirdSectionColOneTitleTwo}</h4>
                                        {aboutData?.ThirdSectionColOneDescTwo}      </div>
                                      <div className="rightBox">
                                          <div className="imgHolder">
                                          <img src={aboutData?.ThirdSectionImage?.data[0]?.attributes?.url}/>
                                          </div><br/><br/>
                                              <h4>{aboutData?.ThirdSectionColTwoTitleOne}</h4>
                                              {aboutData?.ThirdSectionColTwoDescOne}
                                          </div>
                                              <div className="clearBoth"></div>
                                      </div>
                                  </div>
                              </div>
                                  <div className="spBlock greyBg smallPadBot">
                                      <div className="safeArea">
                                          <h2>{aboutData?.ClientSectionTitle}</h2>
                                          <div className="clientsList">
                                              <ul>
                                                {clients.map((client, index) => {
                                                    return (
                                                        <li key={index}><a href={client.Link}><img width={216} height={216} src={client?.attributes?.Image?.data?.attributes?.url}/></a></li>
                                                    )
                                                })}
                                                  {/* <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li>
                                                  <li><img src="./images/logo-client.jpg" alt=""/></li> */}
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                          </div>

                          <Footer></Footer>
    </>
  );
}

export default About;
