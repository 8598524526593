import './styles/styles.css';
import './styles/stylesheet-responsive.css';
import './styles/stylesheet.css';
import Root from './Components/Root';
import ProductDetails from './Components/ProductDetails';
import {
  HashRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './Components/Home';
import About from './Components/About';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    document.title= 'ZeeBoiler';
  }, []);
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/about" element={<About></About>}/>
          <Route path="/" element={<Root></Root>}/>
          <Route path="/product/:productId" element={<ProductDetails></ProductDetails>}>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
