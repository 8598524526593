// import './../styles/stylesheet.css';
import './../styles/styles.css';
import './../styles/stylesheet-responsive.css';
import logo from './../images/logo.svg';
import { getRequest, postRequest } from './../Utils/Requests';
import { useEffect, useRef, useState } from 'react';
import { BACKEND_URL } from './../Utils/Parameters';
import Footer from './Footer';
import { Link, useSearchParams } from 'react-router-dom';
import $ from 'jquery';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Oval} from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";

function Header() {
    const [headerData, setHeaderData] = useState({});
    const [isSendingData, setIsSendingData] = useState(false);
    const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);

    const reCaptchaRef = useRef();
        const firstNameRef = useRef();
        const lastNameRef = useRef();
        const phoneNumberRef = useRef();
        const emailRef = useRef();
        const messageRef = useRef();
        
        const errorToast = (message) => toast.error(message);

        const validateEmail = (email) => {
            return email.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
          };

    useEffect(() => {
        getRequest("header").then(res => {
            setHeaderData(res?.data?.data?.attributes);
        })
    }, []);

    function requestCallback()
    {
        if (!isRecaptchaValid)
        {
            errorToast("reCaptcha required!");
            return;
        }
        if (!firstNameRef.current.value || firstNameRef.current.value.trim() == ""
        || !lastNameRef.current.value || lastNameRef.current.value.trim() == ""
        || !phoneNumberRef.current.value || phoneNumberRef.current.value.trim() == ""
        || !emailRef.current.value || emailRef.current.value.trim() == ""
        || !messageRef.current.value || messageRef.current.value.trim() == "")
        {
            errorToast("Please fill all fields!");
            return;
        }
        if (!validateEmail(emailRef.current.value))
        {
            errorToast("Wrong email format!");
            return;
        }

        let body = {
            data: {
                "Email": emailRef.current.value,
                "PhoneNumber": phoneNumberRef.current.value,
                "Name": firstNameRef.current.value + " " + lastNameRef.current.value,
                "Description": messageRef.current.value,
                "reCaptchaKey": reCaptchaRef.current.getValue(),
            }
        }
        setIsSendingData(true);
        postRequest("call-backs", body).then(res => {
            firstNameRef.current.value = "";
            lastNameRef.current.value = "";
            phoneNumberRef.current.value = "";
            emailRef.current.value = "";
            messageRef.current.value = "";
            setIsSendingData(false);
            togglePopup();
        }).catch(e => {
            errorToast("Something went wrong!");
            setIsSendingData(false);
        })
    }

    function togglePopup()
    {
        $('.callbackPopup').slideToggle();
    }

    function recaptchaCompleted()
    {
        setIsRecaptchaValid(true);
    }
  return (
    <>
      <div className="headerBar">
        <ToastContainer/>
              <div className="safeArea">
                  <div className="leftCol">
                      {headerData?.Description}
                  </div>
                  <div className="requestCallBack">
                      <a href={undefined} onClick={togglePopup}>{headerData?.CallbackText}</a>
                  </div>
                  <div className="clearBoth"></div>

                  <div className="callbackPopup" id="callbackPopup">
                      <div className="popupHolder">
                          <div className="popTitle">Request call-back <a href="" className="close"><img src="./images/close-icon.svg" alt=""/></a></div>
                          <div className="popBody">
                              <div className="inputRow">
                                  <div className="labelHolder"><label>First name</label></div>
                                  <div className="inputHolder">
                                      <input ref={firstNameRef} type="text"/>
                                  </div>
                              </div>
                              <div className="inputRow">
                                  <div className="labelHolder"><label>Last name</label></div>
                                  <div className="inputHolder">
                                      <input ref={lastNameRef} type="text"/>
                                  </div>
                              </div>
                              <div className="inputRow">
                                  <div className="labelHolder"><label>Phone number</label></div>
                                  <div className="inputHolder">
                                      <input ref={phoneNumberRef} type="text"/>
                                  </div>
                              </div>
                              <div className="inputRow">
                                  <div className="labelHolder"><label>Email</label></div>
                                  <div className="inputHolder">
                                      <input ref={emailRef} type="text"/>
                                  </div>
                              </div>
                              <div className="inputRow">
                                  <div className="labelHolder"><label>Message</label></div>
                                  <div className="inputHolder">
                                      <textarea ref={messageRef}></textarea>
                                  </div>
                              </div>
                              <ReCAPTCHA ref={reCaptchaRef} sitekey="6LfjL7YiAAAAAHg8YPWQ_09-zilliIWwfMD1s9B0" onChange={recaptchaCompleted}/>
                              <div className="submitHolder">
                                  
                                  {isSendingData? 
                                  <Oval
                                  height={30}
                                  width={30}
                                  color="#fe6a20"
                                  wrapperStyle={{display: 'inline-block'}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel='oval-loading'
                                  secondaryColor="#fe6a20"
                                  strokeWidth={2}
                                  strokeWidthSecondary={2}
                                
                                /> : <><a href={undefined} onClick={togglePopup}>Cancel</a>
                                <input type="button" value="Submit" onClick={requestCallback}/>
                                </>}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
}

export default Header;
