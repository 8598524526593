import './../styles/styles.css';
import './../styles/stylesheet-responsive.css';
// import './../styles/stylesheet.css';
import logo from './../images/logo.svg';
import { getRequest } from './../Utils/Requests';
import { useEffect, useRef, useState } from 'react';
import { BACKEND_URL } from './../Utils/Parameters';
import Footer from './Footer';
import { Link, useParams } from 'react-router-dom';
import Header from './Header';
import $ from 'jquery';

function ProductDetails(props) {
  const params = useParams()
  const [logo, setLogo] = useState({});
  const [product, setProduct] = useState({});
  const [productSpecifications, setProductSpecifications] = useState([]);

  useEffect(() => {
    getRequest("logo?populate=*").then(res => {
      setLogo(res.data.data.attributes);
    })
  }, []);

  useEffect(() => {
    let productId = params?.productId;
    if (productId)
    {
        getRequest("products/" + productId+"?populate[0]=PreviewImage&populate[1]=product_specifications&populate[2]=product_specifications.Image&populate[3]=product_specifications.TechnicalSheet").then(res => {
            setProduct(res?.data?.data?.attributes);
            setProductSpecifications(res?.data?.data?.attributes["product_specifications"]?.data);
    document.title = "Product Details | ZeeBoiler";
        })
    }
  }, [params]);

//   useEffect(() => {
//     let wholeTable = productSpecifications[0]?.attributes?.TableSpec;
//     if (wholeTable)
//     {
//         let tableOnly = getStringBetween(wholeTable, "<figure class=\"table\">", "</figure>");
//         createTableFromTemplate(tableOnly);
//     }
//   }, [productSpecifications]);

    function getStringBetween(str, start, end) {
        const result = str.match(new RegExp(start + "(.*)" + end));

        return result[1];
    }

    function createTableFromTemplate(template)
    {
        let result = '<table width="100%" border="1"> ' +
                        '<tbody> ' + 
                            '<tr>';
        let templateSplit = template.split("</tr>");
        let firstTr = templateSplit[0];
        firstTr = firstTr.split("<tr>")[1];
        firstTr = firstTr.replaceAll('<td>', '<th scope="col">');
        firstTr = firstTr.replaceAll('</td>', '</th>');
        result += firstTr + "</tr>";
        
        for (let i = 1; i < templateSplit.length - 1 ; i++)
        {
            let innerSplit = templateSplit[i].split("<tr>")[1];

            result += "<tr>";

            innerSplit = innerSplit.replace('<td>', '<th scope="row">');
            innerSplit = innerSplit.replace('</td>', '</th>');

            result += innerSplit;

            result += "</tr>";
        }

        result += "</tbody>";
        result += "</table>";

        return result;
    }

    function togglePopup()
    {
        $('.callbackPopup').slideToggle();
    }
  return (
    <>
          <Header/>
          <div className="header">
              <div className="safeArea">
                  <div className="logoHolder"><Link to="/"><img src={logo?.Logo?.data?.attributes?.url} title="ZeeBoiler logo" alt="ZeeBoiler logo"/></Link></div>
                  <div className="menuHolder">
                      <ul>
                          <li>
                              <Link to="/">Home</Link>
                          </li>
                          <li>
                              <Link to="/about">About us</Link>
                          </li>
                          <li>
                              <a href="/?scrollToProducts=1" className="selected">Products</a>
                          </li>
                      </ul>
                  </div>
                  <div className="clearBoth"></div>
              </div>
          </div>
          <div className="prodHolder">
              <div className="safeArea">
                  <div className="prodImg">
                  <img src={product?.PreviewImage?.data?.attributes?.url} alt=""/>
                  </div>
                  <div className="prodInfo">
                      <div className="breadcrumbs">
                          <ul>
                              <li>
                                  <Link to="/">Home</Link> .
                              </li>
                              <li>
                                  About us
                              </li>
                          </ul>
                      </div>
                      <h1><span dangerouslySetInnerHTML={{ __html: product?.DisplayName }}></span></h1>
                      <div className="bannerSummary">
                          {product?.Description}
                      </div>
                      <div className="LinkStyle">
                          <a href={undefined} onClick={togglePopup}>Contact sales</a>
                      </div>
                  </div>
                  <div className="clearBoth"></div>
              </div>
          </div>

          <div className="hpBlock greyBg">
              <div className="safeArea">
                  <div className="blockTitle">
                      <div className="smallText">{product?.DetailsSectionSmallTitle}</div>
                      <h2 dangerouslySetInnerHTML={{ __html: product?.DetailsSectionBigTitle }}></h2>
                      <div className="blockText">{product?.DetailsSectionDescription}</div>
                  </div>
                  <div className="twoColHolder">
                      <div className="colBlock">
                          <h3>{product?.DetailsSectionColOneTitleOne}</h3>

                          <span dangerouslySetInnerHTML={{ __html: product?.DetailsSectionColOneDescOne }}></span>
                          <h3>{product?.DetailsSectionColOneTitleTwo}</h3>
                          <span dangerouslySetInnerHTML={{ __html: product?.DetailsSectionColOneDescTwo }}></span>

                      </div>
                      <div className="colBlock">
                          <h3>{product?.DetailsSectionColTwoTitle}</h3>

                          <span dangerouslySetInnerHTML={{ __html: product?.DetailsSectionColTwoDesc }}></span>
                      </div>
                      <div className="clearBoth"></div>
                  </div>

              </div>
          </div>

          {productSpecifications.map((spec, index) => {
            return (
                <div key={index} className="spBlock withLeftTitle">
                    <div className="safeArea">
                        <h2>{spec?.attributes?.Title}</h2>
                        <div className="subTitleText">
                            {spec?.attributes?.Description}
                        </div>
                        
                        {spec?.attributes?.Image?.data?.attributes ? (<div className="prodimg imgHolder"><img src={spec?.attributes?.Image?.data?.attributes?.formats?.large?.url} alt=""/></div>) : null}
                        <div className="spcGrid">
                            {
                                spec?.attributes?.TableSpec ? (<span dangerouslySetInnerHTML={{__html: spec?.attributes?.TableSpec}}></span>) : null
                            }
                            
                            {spec?.attributes?.Notes ? (<div className="legend" dangerouslySetInnerHTML={{__html: spec?.attributes?.Notes}}>
                            </div>) : null}
                            
                            {spec?.attributes?.TechnicalSheet?.data ? (<div className="LinkStyle fullSolidColor">
                                <a target={"_blank"} href={spec?.attributes?.TechnicalSheet?.data?.attributes?.url}>Download techincal sheet</a>
                            </div>) : null}
                            
                            <div className="clearBoth"></div>
                        </div>
                    </div>
                </div>
            )
          })} 
          <Footer></Footer>
    </>
  );
}

export default ProductDetails;
