import './../styles/styles.css';
import './../styles/stylesheet-responsive.css';
// import './../styles/stylesheet.css';
import logo from './../images/logo.svg';
import { getRequest } from './../Utils/Requests';
import { useEffect, useState } from 'react';
import { BACKEND_URL } from './../Utils/Parameters';
import Footer from './Footer';
import Home from './Home';
import Header from './Header';
function Root(props) {

  return (
    <>
          <Header/>
          <span><Home/></span>

          <Footer></Footer>
    </>
  );
}

export default Root;
