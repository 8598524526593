import './../styles/styles.css';
import './../styles/stylesheet-responsive.css';
// import './../styles/stylesheet.css';
import logo from './../images/logo.svg';
import { getRequest } from './../Utils/Requests';
import { useEffect, useRef, useState } from 'react';
import { BACKEND_URL } from './../Utils/Parameters';
import Footer from './Footer';
import { Link, useSearchParams } from 'react-router-dom';
function Home() {
  const [homeBannerData, setHomeBannerData] = useState({});
  const [logo, setLogo] = useState({});
  const [products, setProducts] = useState([]);
  const [stats, setStats] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const productsRef = useRef();

  const scrollToProducts = () => productsRef.current.scrollIntoView({behavior: 'smooth'});

  useEffect(() => {
    getRequest("logo?populate=*").then(res => {
      setLogo(res.data.data.attributes);
    })

    getRequest("homepage-banner?populate[0]=BackgroundImage&populate[1]=products&populate[2]=products.PreviewImage&populate[3]=statistics").then(res => {
      console.log("PRODUCTS, ", res?.data?.data?.attributes?.products?.data);
      setHomeBannerData(res.data.data.attributes);
      setProducts([...res?.data?.data?.attributes?.products?.data]);
      setStats([...res?.data?.data?.attributes?.statistics?.data]);
    })

  }, []);

  useEffect(() => {
    if (productsRef)
    {
      if (searchParams.get("scrollToProducts"))
      {
        // setTimeout(function(){scrollToProducts();},200);
        scrollToProducts();
      }
    }
  }, [products]);
  return (
    <>
      <div className="mainBannerHolder" style={{backgroundImage: `url(${homeBannerData?.BackgroundImage?.data?.attributes?.formats?.large?.url})`}}>
        <div className="mainBannerContent">
          <div className="logoHolder">
            <img src={logo?.Logo?.data?.attributes?.url} title="ZeeBoiler logo" alt="ZeeBoiler logo"/>
          </div>
          <div className="captions">
            <div className="smallText">{homeBannerData.SmallTitle}</div>
            <div className="bigText" dangerouslySetInnerHTML={{ __html: homeBannerData.BigTitle}}>
            </div>
            <div className="bannerLinks LinkStyle">
              <Link to="/about">{homeBannerData.AboutUsButtonText}</Link>
              <a href={undefined} onClick={scrollToProducts}>{homeBannerData.ProductsButtonText}</a>
            </div>
          </div>
        </div>
      </div>

      <div className="hpBlock " ref={productsRef}>
        <div className="safeArea">
          <div className="blockTitle">
                      <div className="smallText hpBlackTitle">{homeBannerData?.ProductsSmallTitle}</div>
            <span dangerouslySetInnerHTML={{ __html: homeBannerData.ProductsBigTitle}}></span>
            <div className="blockText">{homeBannerData?.ProductsDescription}</div>
          </div>
          <div className="prodList">
            <ul>
              {products.map((product, index) => {
                return (<li key={index}>
                  <div className="prodImg"><img src={product?.attributes?.PreviewImage?.data?.attributes?.url} alt=""/></div>
                  <div className="prodText">
                    <span dangerouslySetInnerHTML={{ __html: "<h3>" + product?.attributes?.DisplayName + "</h3>"}}></span>
                    {/* <h3>3-pass<br/>dryback boiler</h3> */}
                    <div className="proSummary">{product?.attributes?.Description}</div>
                    <div className="LinkStyle">
                      <Link to={"/product/" + product.id}>Know more</Link>
                    </div>
                  </div>
              </li>)
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="hpBlock greyBg">
        <div className="safeArea">
          <div className="blockTitle">
            <div className="smallText">{homeBannerData?.AboutUsSmallTitle}</div>
            <span dangerouslySetInnerHTML={{ __html: homeBannerData?.AboutUsBigTitle}}></span>
            <div className="blockText">{homeBannerData?.AboutUsDescription}</div>
          </div>
          <div className="factsList">
            <ul>
              {stats.map((stat, index) => {
                return (<li key={index}>
                  <div className="numberHolder">{stat?.attributes?.Stat}</div>
                  <div className="numberText">{stat?.attributes?.Name}</div>
                </li>)
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
}

export default Home;
